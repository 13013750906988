import React from "react";
import { ThemeProvider } from "@kaltura-ds/theme";
import { CustomizationsProvider } from "@kaltura-ds/utils";

const components = {
	button: {
		globalClasses: {
			props: ["variant", "color", "loading", "size", "disabled"],
			booleanTrueValue: "on",
			booleanFalseValue: "off",
		},
	},
};

/**
 * KMS Components Theme Provider - a wrapper to kaltura-ds ThemeProvider, 
 * with CustomizationsProvider set to kms customization requirements.
 */
const KMSThemeProvider: React.FC<any> = (props: any) => {
	const { children, ...rest } = props;

	return (
		<ThemeProvider {...rest}>
			<CustomizationsProvider components={components}>
				{children}
			</CustomizationsProvider>
		</ThemeProvider>
	);
};

export { KMSThemeProvider as ThemeProvider };
