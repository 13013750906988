import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import PersonalProfileProps from "@mediaspace/features/personal-profile/PersonalProfileProps";

const PersonalProfile = React.lazy(() => import("@mediaspace/features/personal-profile/PersonalProfile"));
const WatchlistTab = React.lazy(() => import("@mediaspace/features/personal-profile/watchlist-tab/WatchlistTab"));
const ScheduledWatchlistTab = React.lazy(() => import("@mediaspace/features/personal-profile/scheduled-watchlist-tab/ScheduledWatchlistTab"));


const render = (
    id: string,
    props: PersonalProfileProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <PersonalProfile {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export {
    render,
    WatchlistTab,
    ScheduledWatchlistTab,
};
