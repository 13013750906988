import ReactDOM from "react-dom";
import { Suspense, lazy } from "react";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeProvider } from "@mediaspace/shared/styled";
import { LeaderboardProps } from "@mediaspace/features/leaderboard";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";

const LeaderboardContainer = lazy(() => import("@mediaspace/features/leaderboard"));

const render = (
    id: string,
    props: LeaderboardProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <LeaderboardContainer {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export {
    render,
    LeaderboardContainer,
};
