import ReactDOM from "react-dom";
import React, { Suspense } from "react";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { HeaderMenuProps } from "@mediaspace/header-menu";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";


const HeaderMenu = React.lazy(() => import("@mediaspace/header-menu"));

const render = (
    id: string,
    props: HeaderMenuProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode} scoped={true}>
                    <HeaderMenu {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export { render };
