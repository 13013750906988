import {Typography} from "@kaltura-ds/components";
import styled from "@emotion/styled";
import React from "react";
import ReactHtmlParser from "react-html-parser";

interface FormLabelProps {
    cls?: string;
    text: string;
    htmlFor?: string;
    required?: boolean;
}

const StyledTypography = styled(Typography)(({ theme }: { theme?: any; }) => ({
    color: theme.kaltura.palette.tone2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: theme.spacing(1)
    }
}));

/**
 * Form Label
 */
export const FormLabel = (props: FormLabelProps) => {

    const {
        htmlFor,
        text,
        required,
        cls
    } = props;

    return (
        <label htmlFor={htmlFor} className={cls}>
            <StyledTypography variant={"body2"}>
                {ReactHtmlParser(text)}
                {required ? " *" : ""}
            </StyledTypography>
        </label>
    );
};

export default FormLabel;