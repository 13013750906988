import ReactDOM from "react-dom";
import { Suspense, lazy } from "react";
import { ThemeProvider } from "@kaltura-ds/theme";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UploadMediaContainerProps } from "@mediaspace/features/upload-media";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";

const UploadMediaContainer = lazy(
    () => import("@mediaspace/features/upload-media")
);

const render = (
    id: string,
    props: UploadMediaContainerProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <UploadMediaContainer {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export { render, UploadMediaContainer };
