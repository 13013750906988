export const systemWidthSize = { md: 960, lg: 1200, xl: 1320 };

interface Props {
    theme: any;
}

export const systemWidth = (props: Props) => {
    const { theme } = props;
    return {
        width: systemWidthSize.md,
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down("sm")]: {
            width: "auto",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "auto",
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: systemWidthSize.md,
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            width: systemWidthSize.lg,
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            width: systemWidthSize.xl,
            marginLeft: "auto",
            marginRight: "auto",
        },
    };
};
