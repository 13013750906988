import ReactDOM from "react-dom";
import React, { Suspense } from "react";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AgendaProps } from "@mediaspace/features/agenda";

const Agenda = React.lazy(() => import("@mediaspace/features/agenda"));

export const render = (
    id: string,
    props: AgendaProps & { context: Config }
) => {
    const { theming } = props.context;

    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <Agenda {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};
