export enum ChannelViewMode {
    Detailed = "Detailed",
    Grid = "Grid",
}

export enum ChannelLayoutMode {
    Fixed_Tabs = "Fixed_Tabs",
    Fixed_Vertical = "Fixed_Vertical",
    Tabs = "Tabs",
    Vertical = "Vertical",
}