import ReactDOM from "react-dom";
import React, { Suspense } from "react";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeProvider } from "@mediaspace/shared/styled";
import { FeaturesHomePageProps } from "@mediaspace/features/home-page";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";

const HomePage = React.lazy(() => import("@mediaspace/features/home-page"));
const UpcomingSessions = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/upcoming-sessions/UpcomingSessions"
        )
);
const News = React.lazy(
    () => import("@mediaspace/features/home-page/components/news/News")
);
const SinglePlaylist = React.lazy(
    () =>
        import("@mediaspace/shared/ui/single-playlist/SinglePlaylist")
);
const SingleVideo = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/single-video/SingleVideo"
        )
);
const HeroUnit = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/hero-unit/HeroUnitContainer"
        )
);
const TextAndCounterHeroUnit = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/hero-unit/text-and-counter/TextAndCounterHeroUnit"
            )
);
const HtmlOnly = React.lazy(
    () => import("@mediaspace/features/home-page/components/html-only/HtmlOnly")
);
const TextOnly = React.lazy(
    () => import("@mediaspace/features/home-page/components/text-only/TextOnly")
);
const AdImages = React.lazy(
    () => import("@mediaspace/features/home-page/components/ad-images/AdImages")
);

const render = (
    id: string,
    props: FeaturesHomePageProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <HomePage {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

export {
    News,
    render,
    HeroUnit,
    TextAndCounterHeroUnit,
    SinglePlaylist,
    SingleVideo,
    HtmlOnly,
    TextOnly,
    AdImages,
    UpcomingSessions,
};
