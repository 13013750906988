import ReactDOM from "react-dom";
import React, {ComponentType, Suspense} from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RegistrationSiteHeaderProps } from "@mediaspace/shared/registration-site-header";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";

const RegistrationSiteHeader = React.lazy(() => import("@mediaspace/shared/registration-site-header"));
const Verification = React.lazy(
    () =>
        import(
            "@mediaspace/features/registration/verification/Verification"
        )
);

const Registration = React.lazy(
    () =>
        import(
            "@mediaspace/features/registration/registration/SiteRegistration"
        )
);

const StatusScreen = React.lazy(
    () =>
        import(
            "@mediaspace/shared/ui/status-screen/StatusScreen"
        )
);

const Confirmation = React.lazy(
    () =>
        import(
            "@mediaspace/features/registration/confirmation/Confirmation"
        )
);
const ConfirmationError = React.lazy(
    () =>
        import(
            "@mediaspace/features/registration/confirmation-error/ConfirmationError"
        )
);


/**
 * header is rendered independently in multiple pages, so it deserves its own "render" method
 * @param id
 * @param props
 */
const renderHeader = (
    id: string,
    props: RegistrationSiteHeaderProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <RegistrationSiteHeader {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

/**
 * this will be used to render other components/pages
 */
const render = (
    containerId: string,
    props: React.ComponentProps<any> & { context: Config, component: ComponentType<any> }
) => {

    const { context, component: Component, ...rest } = props;
    const { theming } = context;
    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming.theme} mode={theming.mode}>
                    <Component {...rest} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(containerId)
    );
};

export {
    renderHeader,
    render,
    Verification,
    Registration,
    StatusScreen,
    Confirmation,
    ConfirmationError,
};
